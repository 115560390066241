import React, { useRef} from 'react';
import HeadTags from './components/HeadTags';
import Navbar from './components/Navbar';
import BibtexLoader from './components/bibtexLoader';
import GroupBox from './components/GroupBox';
import ReferencesControl from './components/ReferencesControl';
import PapersView from './components/PapersView';
import QueryControl from './components/QueryControl';
import DownloadButtons from './components/DownloadButtons';
import Warning from './components/Warning';
import Topics from './components/Topics';
import UserInputArea from './components/UserInputArea';
import useStateManager from './scripts/StateManager';
import StatusDisplay from './components/StatusDisplay';
import SelectedTopicDisplay from './components/SelectedTopicDisplay'; 

function App() {
  const suggestedPapersViewRef = useRef(null);

  const initialState = {
    loadingBib: 'not started',
    analyzingReferences: 'not started',
    extractingTopics: 'not started',
    generatingQueries: 'not started',
    gettingRecs: 'not started',
    sortingRecs: 'not started',
    references: [],
    recomendations: [],
    topics: [],
    selectedTopic: '',
    queries: [],
    shouldWarnBibSize: false,
    showRecs: false,
    showReferences: false,
  };
  const { states, setMultipleStates } = useStateManager(initialState);

  const apiUrl = process.env.REACT_APP_API_URL;
  const maxRefs = 100;

  return (
    <div className="App">
      <HeadTags />
      <div className="main">

        <Navbar />
        <div className="main_section">

          {/* status message */}
          <ReferencesControl 
            apiUrl={apiUrl}
            appState={states}
            setAppState={setMultipleStates}
          />

          <StatusDisplay
            appStateDict={{
              "Loading .bib file": states.loadingBib,
              "Analyzing references": states.analyzingReferences,
              "Extracting topics": states.extractingTopics,
            }} />

          <div className="columns is-desktop">
            
            {/* --------------------------- upload user papers --------------------------- */}
            <div className="column is-half-widescreen">
                {/* User references */}
                <GroupBox
                  titleNum="01"
                  title="Your papers"
                  description="Upload a .bib file with the references you want to use to get recommendations."
                  helpContent="You can upload a .bib file containing the references you want to use to get recommendations. Refy will analyze the references and suggest papers that you might find interesting. If you don't have a .bib file, you can use our example file."
                  >
                    
                    {/* bibtext loaders */}
                    <div className="buttons_container">
                      <BibtexLoader 
                        apiUrl={apiUrl}
                        isDefault={false}
                        maxPapers={maxRefs}
                        setAppState={setMultipleStates}
                        initialState={initialState}
                      />
                      <label id="orlabel" className="non-selectable-text"><i>or</i></label>
                      <BibtexLoader 
                        apiUrl={apiUrl}
                        isDefault={true}
                        maxPapers={maxRefs}
                        setAppState={setMultipleStates}
                        initialState={initialState}
                      />
                    </div>

                    {/* warning */}
                    <Warning
                      message="Too many references"
                      description={`You uploaded more than ${maxRefs} references. Refy will only analyze the first ${maxRefs} references.`}
                      appState={states}
                      setAppState={setMultipleStates}
                    />

                    {/* papers content summary */}

                    { states.references.length > 0 && (                  
                    <div className="display_text_box" id="suggested_prompts">
                      <div className="display_text_box_placeholder" style={{'width': '100%'}}></div>
                      <div className="display_text_box_placeholder" style={{'width': '95%'}}></div>
                      <div className="display_text_box_placeholder" style={{'width': '92%'}}></div>
                      <div className="display_text_box_placeholder" style={{'width': '98%'}}></div>
                    </div>
                    )}

                    {/* papers view control */}
                    {states.references.length > 0 &&
                      <div 
                        className="refy_button button_grey show_refs_button" 
                        onClick={() => setMultipleStates({showReferences: !states.showReferences})}>
                        {states.showReferences ? 'Hide your references' : 'View your references'}
                      </div>
                    }

                    {/* papers */}
                    {states.showReferences && <PapersView
                      papers={states.references}
                      setPapersData={(papers) => setMultipleStates({ references: papers })}
                      contentId="empty_paper_user"
                      apiUrl={apiUrl}
                    />}
                  </ GroupBox>
            </div>

            <div className="column is-half-dekstop"> 

               {/* --------------------------- prompt interaction --------------------------- */}

               <GroupBox
                  titleNum = "02"
                  title="Refine your search"
                  description="Select a topic to refine your search for papers. We'll look for new references on this topic that match those in your library."
                  helpContent="Use the text box to describe what you want to learn about. The more specific you are, the better the recommendations will be. If you're unsure what to write, you can leave it empy or use on of the prompts recommended by Refy."
                >
                  
                <Topics 
                  appState={states}
                  setAppState={setMultipleStates}
                />

                <UserInputArea 
                  setAppState={setMultipleStates}
                  appState={states}
                />

                {/* Selected topic */}
                {states.topics.length > 0 &&
                  <SelectedTopicDisplay
                    appState={states}
                  />
                }

              </GroupBox>
            </div>
          </div>

                      


          {/* Query control */}
          <QueryControl 
                apiUrl={apiUrl}
                appState={states}
                setAppState={setMultipleStates}
            />

          {/* --------------------------- suggested papers --------------------------- */}
          <div className="divline" id="recs_divline"/>

          <StatusDisplay
            divID="recs_status"
            appStateDict={{
              "Generating queries": states.generatingQueries,
              "Getting recommendations": states.gettingRecs,
              "Finding the most relevant recommendations": states.sortingRecs,
          }} />
          

      
          <GroupBox
              titleNum="03"
              title="Your recommendations"
              description="Here's what Refy recommends based on the references you provided."
              helpContent="The list below shows publications that Refy thinks you might find interesting. You can click on the title to read the abstract and open the papers' url. You can also export the metadata of the papers as a .csv file. If no papers appear, Refy couldn't find any recommendations based on the references you provided."
              groupID="recs_papers_view"
            >
              {states.showRecs && <>
                <PapersView 
                  ref={suggestedPapersViewRef}
                  setPapersData={(papers) => setMultipleStates({ recomendations: papers })}
                  papers={states.recomendations} 
                  contentId="empty_paper_suggestions"
                  apiUrl={apiUrl}
                />
                <DownloadButtons 
                  papersViewRef={suggestedPapersViewRef} 
                  papers={states.recomendations}
                />
              </>}
          </GroupBox>
        </div>
      </div>
    </div>
  );
}

export default App;