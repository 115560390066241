import React from 'react';

class QueryParamSelector extends React.Component {
  render() {
    return (
        <div className="query_param_selector">
            <label>{this.props.label}: </label>
            <input
                type="text"
                placeholder={this.props.placeholder}
                id={this.props.id}
            />
            

        </div>
      );
    }
}


class QueryYearsSelect extends React.Component {
  render() {
    return (
<div className="query_param_selector">
            <label>Years: </label>
            <input
                type="text"
                placeholder="1900"
                id="start_year_selector"
            />
            <input
                type="text"
                placeholder="2024"
                id="end_year_selector"
            />
            
            

        </div>
      );
    }
}


export {QueryParamSelector, QueryYearsSelect};  