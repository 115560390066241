import React from 'react';

function StatusMessage({ message, status }) {

  let statusIcon;
  if (status === "working on it") {
    statusIcon = <i className="fa-solid fa-hourglass-start busy_icon"></i>
  } else if (status === "done") {
    statusIcon = <i className="fa-solid fa-check"></i>
  } else if (status === "error") {
    statusIcon = <i className="fa-solid fa-exclamation"></i>
  } else {
    statusIcon = <i></i>
  }

  const isActive = status === "working on it" || status === "done" || status === "error";
  const color = isActive ? 'var(--active_text)' : 'var(--inactive_text)'
  const style= {'color': color};
  
  const errorMessage = status === "error" ? "  -- something went wrong, sorry" : "";

  return (
    <li style={style}>
      <span className="icon">{statusIcon}</span> 
      <strong style={style}>{message}</strong>{errorMessage}
    </li>
  );
}


class StatusDisplay extends React.Component {

  isActive(status) {
    return status && status !== 'not started' && status !== 'done';
  }

  render() {
    const { appStateDict } = this.props;

    // Check if any appState is active by iterating over the values in appStateDict
    const shouldDisplay = Object.values(appStateDict).some(status => this.isActive(status));

    // Dynamic styles depending on whether any state is active
    const style = shouldDisplay ?
        {
          'maxHeight': '200px', 
          'padding': "0.5em 1em", 
          'border':'1px solid var(--active_text)',
          'margin': '1rem 0 0.5rem 0'
        } : {
          'maxHeight': '0px', 
          'padding': "0", 
          'border':'none',
          'margin': '0'
        };

    return (
        <div id={this.props.divID}>
          <ul className="status_board" style={style}>
            {
              // Iterate over the dictionary and render each StatusMessage component dynamically
              Object.entries(appStateDict).map(([message, status], index) => (
                <StatusMessage key={index} message={message} status={status} />
              ))
            }
          </ul>
        </div>
    );
  }
}
export default StatusDisplay;