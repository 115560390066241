import React from 'react';


class Paper extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showAbstract: false, // Initial state: abstract is hidden
      checkboxChecked: false,
      abstractClicked: false,
      tldrClicked: false,
    };
  }

  // Open the paper's URL in a new tab if available
  openPaperLink = () => {
    if (this.props.url) {
      window.open(this.props.url, '_blank', 'noopener noreferrer');
    }
  }

  updateCheckboxState = (newState) => {
    this.setState({ checkboxChecked: newState });
  }


  handleCheckboxChange = (e) => {
    e.stopPropagation(); // Prevent the checkbox click from triggering the link

    this.setState(prevState => ({
      checkboxChecked: !prevState.checkboxChecked
    }));

    // callback to update parent
    this.props.checkboxCallback(this.props.index, !this.state.checkboxChecked);    
  }

  toggleAbstractClicked = async () => {

    this.setState({ abstractClicked: !this.state.abstractClicked });    
    // if tldrClicked close tldr
    if (this.state.tldrClicked) {
      this.toggleTldrClicked();
    }
  }
  
  toggleTldrClicked = async () => {
    this.setState({ tldrClicked: !this.state.tldrClicked });
    if (this.state.abstractClicked) {
      this.toggleAbstractClicked();
  }
}
  

  render() {
    const paperStyle = {
      height: (this.state.abstractClicked || this.state.tldrClicked) ? 'auto' : 'var(--element-height)', 
      overflow: 'hidden',
      cursor: this.props.url ? 'pointer' : 'default', // Change cursor to pointer if a URL is available
    };

    const chckedStyle = {'background': 'var(--checked-background)'};
    const uncheckedStyle = {'background': 'var(--unchecked-background)'};
    const checkBoxStyle = this.state.checkboxChecked ? chckedStyle : uncheckedStyle;



    const abstractStyle = this.state.abstractClicked ?
      {
        maxHeight: 'none',
        height: 'auto',
        padding: "1em 2em", 
        margin: '1rem 0 0.5rem 0',
        opacity: 1,
      } : {
        maxHeight: '0px', 
        height: '0px',
        padding: "0", 
        margin: '0',
        overflow: 'hidden',
        opacity: 0,
      };

    const tldrStyle = this.state.tldrClicked ?
      {
        maxHeight: 'none',
        height: 'auto',
        padding: "1em 2em", 
        margin: '1rem 0 0.5rem 0',
        opacity: 1,
      } : {
        maxHeight: '0px', 
        height: '0px',
        padding: "0", 
        margin: '0',
        overflow: 'hidden',
        opacity: 0,
      };

    return (   
      <div className="paper_container" 
          style={paperStyle}
      >

        <div className="paper_checkbox"
          onClick={this.handleCheckboxChange}
          style={checkBoxStyle}
        ></div>


        <div className="paper">
          <div className="paper_title" onClick={this.openPaperLink}>
            {this.props.url && (
              <span className="icon">
                <i className="fa-solid fa-link"></i>
              </span>
            )}
            {this.props.title}
          </div>
          <div className="details columns">
              <div className="year column is-2"> {this.props.year} </div>
              <div className="journal column is-7-dekstop"> {this.props.journal}</div>
              {this.props.citations ? 
                <div className="citations column is-1"> citations: <strong>{this.props.citations}</strong></div> : 
                <div className="citations column is-1"> </div>
              }
          </div>
          <div className="author">{this.props.author}</div>

          <div className="paper_buttons">
            {this.props.abstract && <div 
              className={`paper_button non-selectable-text abstract_button ${this.state.abstractClicked && "active" }`}
              onClick={this.toggleAbstractClicked}
              >Abstract</div>
            }

            {this.props.tldr && 
              <div 
                className={`paper_button non-selectable-text tldr_button ${this.state.tldrClicked && "active" }`}
                onClick={this.toggleTldrClicked}
                >TLDR</div>
            }
          </div>


            <div className="abstract" style={abstractStyle}>
              {this.props.abstract ? (
                <div>
                  <div className="sub_title"><strong>Abstract</strong></div>
                  <div>{this.props.abstract}</div>
                </div>
              ) : (
                <div>No abstract available</div>
              )}
            </div>


            <div className="tldr" style={tldrStyle}>
              {this.props.tldr ? (
                <div>
                  <div className="sub_title"><strong>TL-DR</strong></div>
                  <div>{this.props.tldr}</div>
                </div>
              ) : (
                <div>No TLDR available</div>
              )}
            </div>
            
        </div>
      </div>
    );
  }
}


class EmptyPaper extends React.Component {
  render() {    
      return (
      <div className={`paper empty_paper ${this.props.id}`}>
        <div className="paper_title"></div>
        <div className="details">
          <div className="year"></div>
          <div className="journal"></div>
        </div>
        <div className="author"></div>
        <div className="author"></div>
      </div>
    );
  }
}

export { Paper, EmptyPaper };