// PapersView.js
import React, { Component } from 'react';
import { Paper, EmptyPaper } from './Paper';

/* -------------------------------------------------------------------------- */
/*                                SORT BUTTONS                                */
/* -------------------------------------------------------------------------- */
const SortButtons = ({ sortBy, onSort }) => {
  const sortButtonActiveStyle = {'backgroundColor': 'var(--active_bg)', 'color':'var(--active_text)'};
  const sortButtonInactiveStyle = {'backgroundColor': 'var(--default_bg)', 'color':'var(--default_text)'};

  return (
    <div className="papersview_sort button_group_container">
      <div className="button_group_text">Sort by: </div>
      <div 
        className="button_group button_group_left"
        onClick={() => onSort('relevance')}
        style={sortBy === 'relevance' ? sortButtonActiveStyle : sortButtonInactiveStyle}
      >
        Relevance
      </div>
      <div 
        className="button_group button_group_middle"
        onClick={() => onSort('year')}
        style={sortBy === 'year' ? sortButtonActiveStyle : sortButtonInactiveStyle}
      >
        Year
      </div>
      <div 
        className="button_group button_group_right"
        onClick={() => onSort('citations')}
        style={sortBy === 'citations' ? sortButtonActiveStyle : sortButtonInactiveStyle}
      >
        Citations
      </div>
    </div>
  );
};


/* -------------------------------------------------------------------------- */
/*                             SELECTION CONTROLS                             */
/* -------------------------------------------------------------------------- */
const SelectionControls = ({ onSelectAll, onDiscard, discardStyle, paperCount, selectedCount }) => {
  return (
    <div className="papersview_select level non-selectable-text ">
      <div className="select_controls level-left">
        <div className="button_group button_group_left" onClick={() => onSelectAll(true)}>Select all</div>
        <div className="button_group button_group_right" onClick={() => onSelectAll(false)}>Unselect all</div>
        <div className="discard_selected" onClick={onDiscard} style={discardStyle}>Discard selected</div>
      </div>
      <div className="paperscount level-right non-selectable-text">
        <strong>{paperCount}  </strong> papers 
        (<strong>{selectedCount}</strong> selected)
      </div>
    </div>
  );
};

/* -------------------------------------------------------------------------- */
/*                                 PAPERS VIEW                                */
/* -------------------------------------------------------------------------- */

class PapersView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      papers: [],
      checkedBoxes: [],
      sortBy: 'relevance',
    };
    this.paperRefs = [];
  }

  componentDidMount() {
    this.initializeState();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.papers !== this.props.papers) {
      this.initializeState();
    }
  }

  initializeState = () => {
    const papers = Array.isArray(this.props.papers) ? this.props.papers : [];
    this.setState({
      papers: papers.map((paper, index) => ({ ...paper, originalIndex: index })),
      checkedBoxes: Array(papers.length).fill(false),
      sortBy: 'relevance',
    });
    this.paperRefs = papers.map(() => React.createRef());
  }

  checkboxCallback = (index, newState) => {
    this.setState(prevState => {
      const updatedCheckedBoxes = [...prevState.checkedBoxes];
      updatedCheckedBoxes[index] = newState;
      return { checkedBoxes: updatedCheckedBoxes };
    });
  };

  selectAll = (select) => {
    this.setState(prevState => ({
      checkedBoxes: Array(prevState.papers.length).fill(select)
    }));
    
    this.paperRefs.forEach((ref) => {
      if (ref.current && typeof ref.current.updateCheckboxState === 'function') {
        ref.current.updateCheckboxState(select);
      }
    });
  };

  discardSelected = () => {
    this.setState(prevState => {
      const updatedPapers = prevState.papers.filter((_, index) => !prevState.checkedBoxes[index]);
      
      return {
        papers: updatedPapers,
        checkedBoxes: Array(updatedPapers.length).fill(false)
      };
    }, () => {
      // if (typeof this.props.setKeywords === 'function') {
      //   this.props.setKeywords([]);
      // }
      this.selectAll(false);
      if (typeof this.props.setPapersData === 'function') {
        this.props.setPapersData(this.state.papers);
      }
      console.log('After removing papers:', this.props.papers);
    });
  }

  sortPapers = (sortBy) => {
    this.setState(prevState => {
      let sortedPapers;
      if (sortBy === 'relevance') {
        sortedPapers = [...prevState.papers].sort((a, b) => a.originalIndex - b.originalIndex);
      } else {
        sortedPapers = [...prevState.papers].sort((a, b) => {
          switch (sortBy) {
            case 'year':
              return b.year - a.year;
            case 'citations':
              return b.citations - a.citations;
            default:
              return 0;
          }
        });
      }

      return { papers: sortedPapers, sortBy };
    });
  }

  render() {
    const { papers, checkedBoxes, sortBy } = this.state;
    const paperComponents = papers.length === 0 
      ? Array(5).fill().map((_, i) => <EmptyPaper key={i} id={this.props.contentId}/>)
      : papers.map((paper, index) => (
          <Paper
            key={index}
            ref={this.paperRefs[index]}
            index={index}
            title={paper.title}
            journal={paper.journal}
            author={paper.author}
            year={paper.year}
            abstract={paper.abstract}
            tldr={paper.tldr}
            citations={paper.citations}
            url={paper.url}
            checkboxCallback={this.checkboxCallback}
          />
        ));

    const selectedPapers = checkedBoxes.filter(value => value).length;
    const discardStyle = {'opacity': selectedPapers > 0 ? 1 : 0};

    return (
      <div className="papersview" id={this.props.paperviewId}>
        {papers.length > 0 && <SortButtons sortBy={sortBy} onSort={this.sortPapers} />}
        <div className="paperslist">
          {paperComponents}
        </div>
        {papers.length > 0 &&
            <SelectionControls
              onSelectAll={this.selectAll}
              onDiscard={this.discardSelected}
              discardStyle={discardStyle}
              paperCount={papers.length}
              selectedCount={selectedPapers}
            />
        }
      </div>
    );
  }
}

export default PapersView;