import React, { useState } from 'react';

const GroupBox = ({
  titleNum,
  title,
  description,
  helpContent,
  groupID,
  children, 
}) => {
  const [showHelp, setShowHelp] = useState(false);

  const toggleHelp = () => {
    setShowHelp(prevShowHelp => !prevShowHelp);
  };

  const hideHelp = () => {
    setTimeout(() => {
      setShowHelp(false);
    }, 300);
  }


  return (
      
    <div className="group_box" onMouseLeave={hideHelp}>
      <div className="group_title non-selectable-text" id={groupID}><strong>{titleNum}</strong> {title}</div>
      {description && <div className="group_description non-selectable-text">{description}</div>}
      <div className="help_button" onClick={toggleHelp}>?</div>
      <div className="group_help" style={showHelp ? {'display':'block'} : {'display':'none'}}>
        <strong>Instructions: </strong>
        {helpContent}
      </div>
      <div className="group_content">
        {children} 
      </div>
    </div>
  );
};


export default GroupBox;
