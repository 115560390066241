import React from 'react';

class Topics extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      topics: [],
    };
  }
  
  componentDidMount() {
    // Set initial topics from props when component first mounts
    if (this.props.appState.topics && this.props.appState.topics.length > 0) {
      this.setState({ topics: this.props.appState.topics });
    }
  }

  componentDidUpdate(prevProps) {
    // Only update the topics state if the topics actually changed
    if (prevProps.appState.topics !== this.props.appState.topics) {
      // if (this.props.appState.topics.length === 0) {
      //   return;
      // }
      // Only update state if new topics are different from the current ones
      if (JSON.stringify(this.state.topics) !== JSON.stringify(this.props.appState.topics)) {
        this.setState({ topics: this.props.appState.topics });
      }
    }
  }

  onTopicClick = (topic) => {
    console.log('Topic clicked:', topic);
    this.props.setAppState({ selectedTopic: topic });
  }


  render() {
    const selected = this.props.appState.selectedTopic;

  
    return (
      <>
        {this.state.topics.length > 0 && (
          <div>Select one of the topics related to your papers:</div>
        )}
        <div className="topics non-selectable-text" id="topics_div">
          {this.state.topics.length > 0 ? (
            this.state.topics.map((topic, index) => (
              <div
                key={index}
                className={`topic ${selected && selected === topic ? 'topic_selected' : ''}`}
                onClick={() => this.onTopicClick(topic)}
              >
                <p>{topic}</p>
              </div>
            ))
          ) : (
            <div id="topics_placeholders_container">
            </div>
          )}
        </div>
      </>
    );
  }
  
}

export default Topics;
