import React from 'react';
import sendPostRequest from '../scripts/Requests';
import { QueryParamSelector, QueryYearsSelect } from './QueryParamSelector';

class QueryControl extends React.Component {
  
  handleRecommendPapers = async () => {
    // reset state
    this.props.setAppState({
      gettingRecs: 'not started',
      sortingRecs: 'not started',
      generatingQueries: 'working on it',
      queries: [],
      showRecs: true,
      recomendations: []
    })


    // scroll after a delay
    
    setTimeout(async () => {
      document.getElementById('recs_status').scrollIntoView({ behavior: 'smooth',});
      this.animatePlaceholders();
    }
    , 500);
    

    // get a list of queries for the topic
    const _queries = await sendPostRequest(
      `${this.props.apiUrl}/backend/queries`,
      JSON.stringify({ topic: this.props.appState.selectedTopic }),
      'Getting prompt suggestions',
      { 'Content-Type': 'application/json' }
    );

    if (_queries && _queries.result) {
      this.props.setAppState({
        queries: _queries.result,
        generatingQueries: 'done',
        gettingRecs: 'working on it',
      });
    } else {
      console.error('Error getting queries:', _queries);
      this.props.setAppState({
        queries: [],
        generatingQueries: 'error',
      })
    }
    console.log('Queries for topic:', _queries.result);


    // Get start/end year value
    const startYear = document.getElementById('start_year_selector').value;
    const endYear = document.getElementById('end_year_selector').value;
    const nSuggestions = document.getElementById('n_suggestions_to_retun').value;

    // Send request to get suggested papers
    console.log('Getting suggested papers...');
    const suggestion = await sendPostRequest(
      `${this.props.apiUrl}/backend/suggest`,
      JSON.stringify({
        queries: _queries.result,
        references: this.props.appState.references,
        start_year: startYear,
        end_year: endYear,
        n_suggestions_to_retun: nSuggestions
      }),
      'Getting suggested papers',
      { 'Content-Type': 'application/json' }
    );

    // Update suggested papers view
    if (suggestion) {
      console.log('Suggested papers:', suggestion.result);
      this.props.setAppState({ 
        gettingRecs: 'done',
        recomendations: suggestion.result,
        showRecs: true,
    });


      // Score and sort recs based on relevance - backend
      this.props.setAppState({sortingRecs: "working on it"});
      const sortedSuggestion = await sendPostRequest(
        `${this.props.apiUrl}/backend/score`,
        JSON.stringify({
          references: this.props.appState.references,
          suggestions: suggestion.result
        }),
        'Getting suggested papers',
        { 'Content-Type': 'application/json' }
      );

      this.props.setAppState({ 
        recomendations: [],
      })
      // wait a sec
      await new Promise(r => setTimeout(r, 500));

      if (sortedSuggestion) {
        console.log('Sorted suggestions');
        this.props.setAppState({ 
          recomendations: sortedSuggestion.result,
          sortingRecs: "done",
        });
      } else {
        console.error('Error sorting suggestions:', sortedSuggestion);
        this.props.setAppState({ sortingRecs: "error" });
      }
    
    // scroll view to #suggestions_section
    // document.getElementById('recs_papers_view').scrollIntoView({ behavior: 'smooth' });
    } else {
      this.props.setAppState({ gettingRecs: 'error' });
    }

    
  }

  animatePlaceholders = () => {
    const animateElements = (selector) => {
      const elements = document.querySelectorAll(selector);
      console.log('Animating elements:', elements);
      elements.forEach((element, index) => {
        element.style.animation = 'fadeCycle 2s infinite ease-in-out';
        element.style.animationDelay = `${index * 0.1}s`;
      });
    };

    animateElements('.empty_paper');
  }


  render() {
    const gettingRecs = this.props.appState.gettingRecs;
    const sortingRecs = this.props.appState.sortingRecs;
    const isBusy = gettingRecs === 'working on it' || sortingRecs === 'working on it';
    const isEnabled = this.props.appState.selectedTopic !== '' && !isBusy;
    const buttonClass = !isEnabled
      ? "refy_button button_red_disabled button_wide"
      : "refy_button button_red button_wide";

    const busyBottonMessage = gettingRecs === "working on it" ? "Fetching recommendations" : "Sorting recommendations";
    const buttonMessage = isBusy ? busyBottonMessage : "Recommend papers";

    return (
      <>
        {/* ----------------------------- query controls ----------------------------- */}
        <div className="query_controls_container columns is-half">
          <div className="column is-half">
            <div className="query_controls ">
              {/* <div>Query parameters</div> */}
              <QueryYearsSelect />
              <QueryParamSelector label="# papers (max 100)" placeholder="200" id="n_suggestions_to_retun" />
            </div>
          </div>
          <div className="column is-half">
            <label
              className={buttonClass}
              id="suggest_button"
              onClick={this.handleRecommendPapers}
            >
              {buttonMessage}
            </label>
          </div>


        </div>
      </>
    );
  }
}

export default QueryControl;