import React from 'react';

class DownloadButtons extends React.Component {
  getSelectedPapers = (allPapers, checkedBoxes) => {
    return allPapers.filter((_, index) => checkedBoxes[index]);
  }

  convertToCSV = (papers) => {
    if (!papers || papers.length === 0) {
      return '';
    }
    const headers = ['title', 'journal', 'author', 'year', 'abstract', 'citations', 'url'].join(',');
    const rows = papers.map(paper =>
      [
        paper.title,
        paper.journal,
        paper.author,
        paper.year,
        paper.abstract,
        paper.citations,
        paper.url
      ].map(value =>
        value === null || value === undefined ? '' :
          typeof value === 'string' ? `"${value.replace(/"/g, '""')}"` : value
      ).join(',')
    );
    return [headers, ...rows].join('\n');
  }

  downloadCSV = (csvContent, filename) => {
    if (!csvContent) {
      alert('No data available for download');
      return;
    }
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    if (link.download !== undefined) {
      const url = URL.createObjectURL(blob);
      link.setAttribute('href', url);
      link.setAttribute('download', filename);
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }

  downloadPapers = (getAllPapers = false) => {
    const papersViewComponent = this.props.papersViewRef.current;
    if (!papersViewComponent) {
      console.error('PapersView component not found');
      return;
    }
    
    const allPapers = this.props.papers || [];
    if (allPapers.length === 0) {
      alert('No papers available for download');
      return;
    }
    
    const checkedBoxes = papersViewComponent.state.checkedBoxes || [];
    const papersToDownload = getAllPapers ? allPapers : this.getSelectedPapers(allPapers, checkedBoxes);
    
    if (papersToDownload.length === 0) {
      alert('No papers selected for download');
      return;
    }
    console.log(`Downloading ${papersToDownload.length} papers`);
    const csvContent = this.convertToCSV(papersToDownload);
    this.downloadCSV(csvContent, getAllPapers ? 'all_papers.csv' : 'selected_papers.csv');
  }

  render() {
    // const papersViewComponent = this.props.papersViewRef.current;
    // const hasPapers = papersViewComponent && papersViewComponent.state.visiblePapers.length > 0;
    // const hasPapers = papersViewComponent && papersViewComponent.state.visiblePapers && papersViewComponent.state.visiblePapers.length > 0;
    const hasPapers = this.props.papers && this.props.papers.length > 0;

    return (
      <div className="buttons_container">
        <button
          className={`refy_button ${hasPapers ? 'button_red' : 'button_red_disabled'}`}
          onClick={() => this.downloadPapers(true)}
          disabled={!hasPapers}
        >
          Download All Papers
        </button>
        <label id="orlabel" className="non-selectable-text"><i>or</i></label>
        <button
          className={`refy_button ${hasPapers ? 'button_white' : 'button_white_disabled'}`}
          onClick={() => this.downloadPapers(false)}
          disabled={!hasPapers}
        >
          Download Selected Papers
        </button>
      </div>
    );
  }
}

export default DownloadButtons;