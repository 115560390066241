import React from 'react';

class Warning extends React.Component {

  onClose = () => {
    console.log('closing warning');
    this.props.setAppState({ shouldWarnBibSize: false });
  }

  render() {
    const style = this.props.appState.shouldWarnBibSize ?
        {
          'maxHeight': '200px', 
          'padding': "0.5em 1em", 
          'border':'0.5px solid var(--border-color)',
          'margin': '1rem 0 0.5rem 0'
        } : {
          'maxHeight': '0px', 
          'padding': "0", 
          'border':'none',
          'margin': '0'
        };

    return (
        
        <div className='warning' style={style}>
            <strong>{this.props.message}</strong>
            <p>{this.props.description}</p>

            <div className='warning_button' onClick={this.onClose}>Close</div>
        </div>
      
      );
    }
}

export default Warning;