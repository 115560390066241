// write a function to send post requests
export default async function sendPostRequest(url, body, name, headers=null) {
    try {
          const options = {
            method: 'POST',
            body: body
        };
  
        // Add headers only if they are provided
        if (headers) {
            options.headers = headers;
        }
  
        const response = await fetch(url, options);
  
        if (response.ok) {
            const data = await response.json();
            console.log(`${name} POST request successful`);
            return data; 
        } else {
            console.error(`${name} POST request failed`);
            return null;
        }
    } catch (error) {
        console.error('Error:', error);
        return null;
    }
  }
  

