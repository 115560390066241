import React, { useState } from 'react';
import Modal from './modal';

const Navbar = () => {
  const [isAboutModalOpen, setIsAboutModalOpen] = useState(false);
  const [isInstructionsModalOpen, setIsInstructionsModalOpen] = useState(false);

  return (
    <div>
      <nav className="refy_navbar">
        <div className="navbar_brand non-selectable-text">
          Refy
        </div>
        <div className="navbar_links">
          <div 
            className="navbar_link non-selectable-text"
            onClick={() => setIsAboutModalOpen(true)}
          >
            About
          </div>
          <div 
            className="navbar_link non-selectable-text"
            onClick={() => setIsInstructionsModalOpen(true)}
          >
            Instructions
          </div>
        </div>
      </nav>

      <Modal isOpen={isAboutModalOpen} onClose={() => setIsAboutModalOpen(false)}>
        <div className="modal_title">
          About Refy
        </div>
        <div className="modal_content">
          <strong>Refy</strong> is a <i>paper recomendation system</i>.
          It looks at papers you've previously read and aims to recommend new 
          papers that you might find interesting.

          <br style={{"margin-bottom":"0.75rem"}} />
          <b>How does it work?</b><br />
          <strong>Add this part in when the algorithm is finalized</strong>

          <br style={{"margin-bottom":"0.75rem"}} />
          <b>How do I use it?</b><br />
          Click on the <i>Instructions</i> link to learn how to use Refy.

          <br style={{"margin-bottom":"0.75rem"}} />
          <b>How does it compare to other similar tools?</b><br />
          <strong>Refy</strong> is unique because it uses <b>multi papers</b> from your 
          library to find relevant new articles to recommend. Most other tools rely only on 
          search queries or keywords or a single paper to make recommendations.
          By using multiple papers, <strong>Refy</strong> can provide more accurate and
          relevant recommendations.

          <br style={{"margin-bottom":"0.75rem"}} />
          <b>Who made this?</b><br />
          Refy was made by <a href="https://www.linkedin.com/in/federico-claudi-3b31b5262/" target="_blank">
          Federico Claudi </a>


          <br style={{"margin-bottom":"0.75rem"}} />
          <b>Will Refy always be free?</b><br />
          No. Refy is free while we develop and will always have a free version, but 
          we will introduce a paid version with more features in the future.
        </div>
      </Modal>

      <Modal isOpen={isInstructionsModalOpen} onClose={() => setIsInstructionsModalOpen(false)}>
      <div className="modal_title">
          Instructions
        </div>
        <div className="modal_content">
        <br style={{"margin-bottom":"0.75rem"}} />
        To use Refy, you need to do to three things: <b>upload </b> a list of references
        from papers you've read, <b>provide a prompt </b> to refine your search and enjoy
        the <b>recommendations </b> Refy provides.

        <br style={{"margin-bottom":"0.75rem"}} />
        <b>Uploading references</b><br />
        Refy uses the <a href="https://www.bibtex.org/" target="_blank">BibTeX</a> format to
        upload references. You can find the BibTeX references in the papers you've read, on Google Scholar
        and most reference manager software. 
        You should include at least 5 references to get good recommendations and should try 
        to include references on specific topics you're interested in. Refy will aim to find 
        recommendations based on the topics covered in the references you provide.
        Once you have a .bib file ready, you can uploading by pressing on the corresponding button. 
        If you don't have a .bib file, you can use the example file provided.
        
        <br style={{"margin-bottom":"0.75rem"}} />
        <b>Refining the search</b><br />
        Once you've uploaded your references, you can provide a prompt to refine your search.
        For example, say you've uploaded references about climate change, but you're particularly
        interested in mathematical models of climate change. You can provide a prompt like: "Focus 
        the serach on mathematical models of climate change applied to ocean temperatures".
        If you're not sure what to write, you can leave the prompt blank or use one of the recommended 
        prompts provided by Refy.

        <br style={{"margin-bottom":"0.75rem"}} />
        <b>Getting recommendations</b><br />
        Once you've uploaded your references and provided a prompt, you can get your recommendations 
        by clicking on the corresponding button. After a couple of seconds, Refy will show a list of papers
        that you might find interesting. You can click on a paper to read its abstract or visit the paper's url.
        You can also export all (or a subset) of the recommended papers metadata as a .csv file.
        Don't forget that you can further refine your search by selecting a range of dates for the papers you want to see.
        </div>


      </Modal>
    </div>
  );
};

export default Navbar;